@import "../../styles/bootstrap";

.Footer {
  color: $gray-500;
}

.divider {
  border: none;
  height: 1px;
  color: #353842; /* old IE */
  background: $steel_20; /* Modern Browsers */
}

