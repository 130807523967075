@import "../../styles/bootstrap";

.DiscoveryCard {
  min-height: 120px;
  display: flex;
  justify-content: space-between;
  border: none;
  text-decoration: none;
}

.Slider-arrow {
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  transform: translate(0, -50%);
  cursor: pointer;
}

.Slider-arrow-start {
  left: -25px;
}

.Slider-arrow-end {
  right: -25px;
}
